import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LoginForm } from "@pearlchain/stackbase-common"
import { push } from 'connected-react-router';
import { clearState } from './core/dashboard/actions/dashboard-actions';
import { clearCachedData } from '@pearlchain/component-lib-common';

type Props = {
    clearState: typeof clearState;
    clearCachedData: typeof clearCachedData;
    push: typeof push;
};

function LoginPage(props: Props) {
    const [hasToken, setHasToken] = useState(false) 

    useEffect(() => {
        var url_string = window.location.href
        var url = new URL(url_string);
        var token = url.searchParams.get("auth-token");
        if(token){
            setHasToken(true)
            window.localStorage.setItem('auth-token',token)
            props.push('/');
        }
    }, []);

    function onLoginSuccess() {
        props.push('/');
    }

    useEffect(() => {
        props.clearCachedData([]);
        props.clearState();
    }, []);

    if(hasToken) return <div>Loading</div>

    return <LoginForm onLoginSuccess={onLoginSuccess} />;
}

export default connect(
    undefined,
    { clearState, clearCachedData, push }
)(LoginPage);
