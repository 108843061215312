import React, { useContext, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { WidgetActions } from '../../../types/widgetTypes';
import { DashboardActionType } from '../../../types/dashboardTypes';
import WidgetHeaderTitle from './WidgetHeaderTitle';
import WidgetHeaderOptionsDropdown from './WidgetHeaderOptionsDropdown';
import { DrilldownEntry } from '../../../types/drilldownTypes';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';
import { DashboardContext } from '../../dashboard/context';
import { createFullLayout } from '../../layout/layoutHelpers';
import { LayoutType } from '../../layout/layoutTypes';
import { useTranslation } from 'react-i18next';

type Props = {
    widgetUuid: string;
    drilldown: DrilldownEntry[];
    widgetName: string;
    viewMode: WidgetViewMode;
    loading: boolean;
    actions: WidgetActions;
};

export default React.memo(function WidgetHeader(props: Props) {
    const { widgetUuid, drilldown, widgetName, viewMode, loading, actions } = props;
    const [isShowingOptions, setShowingOptions] = useState(false);
    const { t } = useTranslation();

    const ctx = useContext(DashboardContext);
    const layout = ctx.state.layout;

    const isFull = layout ? layout.type === LayoutType.FULL : false;
    const isReadonly = window.location.href.includes('readonly')

    //Toggles widget settings dropdown
    const onToggleOptions = useCallback((e) => {
       setShowingOptions(!isShowingOptions);
    }, [isShowingOptions]);

    //Toggles maximized widget view
    function onToggleExpanded() {
        if (isFull) {
            ctx.dispatch({ type: DashboardActionType.SET_LAYOUT, layout: undefined });
        } else {
            const layout = createFullLayout(widgetUuid);
            ctx.dispatch({ type: DashboardActionType.SET_LAYOUT, layout });
        }
    }

    return (
        <div className="widget-header">
            <div className={cn("widget-header-item", { hidden: viewMode === WidgetViewMode.CONFIG })} key="refresh" onClick={() => actions.triggerRefresh(true)}>
                <FontAwesomeIcon icon="sync" spin={loading} />
            </div>
            <WidgetHeaderTitle widgetName={t(widgetName)} drilldown={drilldown} actions={actions} />
            <div className="widget-header-item" key="expand" onClick={onToggleExpanded}>
                <FontAwesomeIcon icon={isFull ? 'compress' : 'expand'} />
            </div>
            {!isReadonly && 
            <div className="widget-header-item" key="options">
                <div style={{ width: '100%', height: '100%' }} onClick={onToggleOptions}>
                    <FontAwesomeIcon icon="bars" />
                </div>
                <WidgetHeaderOptionsDropdown
                    widgetUuid={widgetUuid}
                    isShowing={isShowingOptions}
                    actions={actions}
                    onHide={onToggleOptions}
                />
            </div>
            }
            
        </div>
    );
});
