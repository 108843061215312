import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import FormFieldErrorFeedback from '../../common/config/formik/FormFieldErrorFeedback';
import ReportColumnChooserField from '../../common/config/formik/ReportColumnChooserField';
import DataSourceField from '../../common/config/formik/DataSourceField';

type Props = {
    reportSource: string;
    sqlParamsValues: { [key: string]: any };
    setFieldValue: (field: string, value: any) => void;
};

export default React.memo(function PieChartWidgetBasicConfig(props: Props) {
    const { t } = useTranslation();
    return (
        <>
            <DataSourceField
                reportSource={props.reportSource}
                name='reportSource'
                typeName='type'
                sqlParamsName="sqlParams"
                sqlParamsValues={props.sqlParamsValues}
                setFieldValue={props.setFieldValue}
                isSingleSource={false}
            />
            <Form.Group>
                <Form.Label>{t('dashboard.group.by')}</Form.Label>
                <ReportColumnChooserField name="groupBy" reportSource={props.reportSource} optional={true}/>
                <FormFieldErrorFeedback name="groupBy" />
            </Form.Group>
        </>
    );
});
