import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Form, FormControl, Nav } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';

import { HumanTaskFormPropertyResponse } from '../../types/responseTypes';
import moment = require('moment');

type Props = {
    formField: HumanTaskFormPropertyResponse;
    id: string;
} & FieldProps;


function makeStringInput({ formField, field, form, id }: Props) {
    const disabled = !formField.writable;
    const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    if((disabled) && field.value != null && (field.value.startsWith("http") ||field.value.startsWith("https"))) {
        return (<Nav defaultActiveKey={field.value} as="ul">
                    <Nav.Item as="li">
                        <Nav.Link href={field.value} target="_blank">{field.value}</Nav.Link>
                    </Nav.Item>
                </Nav>);
    }
    return <FormControl id={id} disabled={disabled} isInvalid={isInvalid} {...field} />;
}

function makeDoubleInput({ formField, field, form, id }: Props) {
    const disabled = !formField.writable;
    const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return <FormControl type="number" id={id} disabled={disabled} isInvalid={isInvalid} {...field} />;
}

function makeBooleanInput({ formField, field, form, id }: Props) {
    const disabled = !formField.writable;
    const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    const name = formField.name;
    return (
        <Form.Check
            type="checkbox"
            id={id}
            disabled={disabled}
            isInvalid={isInvalid}
            checked={field.value}
            {...field}
        />
    );
}

function makeDateInput({ formField, field, id }: Props) {
    const disabled = !formField.writable;
    const format = "DD-MM-YYYY HH:mm:ss";
    const value = moment(Number(field.value)).toDate();
    return (
        <DateTimePicker
            id={id}
            onChange={field.onChange}
            format={format}
            disabled={disabled}
            value={value}
            culture="en"
        />
    );
}

function getOptionValues(enumValues: { [key: string]: string }) {
    return Object.entries(enumValues).map(([k, v]) => ({ label: v, value: k }));
}

function makeEnumInput({ formField, field, form }: Props) {
    const disabled = !formField.writable;
    const enumValues = formField.enumerationValues;
    const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    const optionValues = getOptionValues(enumValues);
    return (
        <FormControl as="select" disabled={disabled} isInvalid={isInvalid} {...field}>
            <option value="" disabled></option>
            { optionValues.map((item, i) => (
                <option key={i} label={item.label} value={item.value} disabled={disabled}/>
            ))}
        </FormControl>
    );
}

function makeTextAreaInput({ formField, field, form, id }: Props) {
    const disabled = !formField.writable;
    const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return <FormControl as="textarea" rows={3} id={id} disabled={disabled} isInvalid={isInvalid} {...field} />;
}

export default function FormGeneratorFieldInput(props: Props) {
    const fieldType = props.formField.type;
    switch (fieldType) {
        case 'string':
            return makeStringInput(props);
        case 'double':
            return makeDoubleInput(props);
        case 'long':
            return makeDoubleInput(props);
        case 'boolean':
            return makeBooleanInput(props);
        case 'enum':
            return makeEnumInput(props);
        case 'date':
            return makeDateInput(props);
        case 'textArea':
            return makeTextAreaInput(props);
        default:
            throw new Error('Unrecognized field type - ' + fieldType);
    }
}
