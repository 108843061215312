import React, { useMemo, useCallback } from 'react';
import { Formik } from 'formik';

import { WidgetCommonConfig } from '../../../types/storeTypes';
import { EnterpriseDesignerConfig } from './enterpriseDesignerTypes';
import EnterpriseDesignerConfigForm from './EnterpriseDesignerConfigForm';

type Props = {
    common: WidgetCommonConfig;
    config: EnterpriseDesignerConfig | undefined;
    configure: (common: WidgetCommonConfig, config: EnterpriseDesignerConfig) => void;
};

export type EnterpriseDesignerFormValues = EnterpriseDesignerConfig & WidgetCommonConfig;
const DEFAULT_CONFIG: EnterpriseDesignerConfig = {
    designId: '',
    selectorConfigs: ''
};

export default function EnterpriseDesignerConfig(props: Props) {

    // the initial form values
    const initialValues = useMemo((): EnterpriseDesignerFormValues => {
        return Object.assign({}, props.common, props.config || DEFAULT_CONFIG);
    }, [props.common, props.config]);

    // the function called to save the form values on the widget
    const onSubmit = useCallback((values: EnterpriseDesignerFormValues) => {
        const common: WidgetCommonConfig = {
            pollInterval: values.pollInterval,
            widgetName: values.widgetName,
            events: values.events
        };

        const config: EnterpriseDesignerConfig = {
            designId: values.designId,
            selectorConfigs: values.selectorConfigs
        };

        props.configure(common, config);
    }, [props.configure]);

    return <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={EnterpriseDesignerConfigForm}></Formik>;
}
