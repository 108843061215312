import React, { Suspense } from 'react';
import { render } from 'react-dom';

// Import styles
import '@pearlchain/enterprise-designer/src/styles/sass/main.scss';
import '@pearlchain/component-lib-ui/src/styles/_pearlchain-web.scss';

import 'react-grid-layout/css/styles.css';
import 'react-widgets/dist/css/react-widgets.css';
import './styles/main.scss';

import App from './App'
import DashboardPage from './core/dashboard/components/DashboardPage'
import ReadonlyDashboard from 'core/dashboard/components/ReadonlyDashboard'
import LoginPage from './LoginPage';

import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux'
import store, { history } from './store'

import moment from 'moment';
import momentLocalizer from 'react-widgets-moment'

moment.locale('en')
momentLocalizer();

import './fa';

// Setup i18n
import { i18nSetup } from '@pearlchain/component-lib-common';
i18nSetup();

const fallback = <h5>Loading translations...</h5>

const router = (

    <Provider store={store}>
      <Suspense fallback={fallback}>
        <ConnectedRouter history={history}>
          <App>
            <Switch>
              <Route exact path="/" component={DashboardPage}/>
              <Route exact path="/readonly" component={ReadonlyDashboard}/>
              <Route path="/login" component={LoginPage}/>
            </Switch>
          </App>
        </ConnectedRouter>
      </Suspense>
    </Provider>
)

render(router, document.getElementById('root'))
