import React, { useState } from "react";
import { Formik, FormikProps } from "formik";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { getWidgetDefinitionByType } from "../../../utils/availableWidgets";
import ExportDataModalForm, { FormValues } from "./ExportDataModalForm";
import DataSourceExportDataModalForm from "./DataSourceExportDataModalForm";
import { EXPORT_FORMATS } from "./fileUtils";
import { EvaluationContext } from "../../../utils/placeholders/placeholderTypes";
import { Widget } from "../../../types/storeTypes";
import { DataSource } from "../../../types/widgetTypes";
import { ReportParamConfig } from "../../../types/configTypes";
import { useDashboardContext } from "../../dashboard/context";
import { useWidgetContext } from "../context";
import { invokeDataSource, runQuery } from "core/dashboard/utils/requests/requestHandlers";
import { RESULTS_PER_PAGE } from "../../widgets/singlesource/view/singleSourceRequestHandler";
import { reportParamsConfigToRequest } from "core/dashboard/utils/requests/requestHelpers";
import { Evaluator } from "core/dashboard/evaluator/Evaluator";
import { PropertySource } from "core/dashboard/evaluator/PropertySource";
import { Param, paramsToObject } from "../../config/datasource/Editor";
import { DEFAULT } from "../../sourceMapping/fetchSourcesData";

async function downloadReport(values: FormValues, widget: Widget<any>, evaluator: Evaluator, isExport:boolean) {
    const sql = widget.dataSources[0].definition;
    const params = widget.dataSources[0].params;
    const type = widget.dataSources[0].type;
    let result:{[key: string]: unknown}[] = []
    const pagination = widget.config.pagination || DEFAULT; 
    const pollInterval = widget.common.pollInterval; 
    const orderColumn = widget.config.orderColumn

    if(type === "SQL"){
        const parameters = evaluator.evaluateObject(paramsToObject(params as Param[]))
        const request = {sql, preview: false, offset: 0, count: 50, params: parameters, sortColumns: [], orderColumn , pagination, pollInterval, refreshCache: false, isExport}
        result = await runQuery(request);
    }
    else {
        const r = await invokeDataSource({
            dataSourceName: widget.config.reportSource, 
            functionName: widget.config.providersHandlers, 
            offset:  0, 
            count: RESULTS_PER_PAGE, 
            params: reportParamsConfigToRequest(widget.config.reportParams),
        })
        result = r.result;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData: {[key: string]: unknown}[], fileName: string) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    exportToCSV(result, values.fileName)
}

function listWidgetSources(widget: Widget<unknown>): DataSource[] {
    const widgetDef = getWidgetDefinitionByType(widget.type);
    return widgetDef.listSources(widget.config);
}

export default function ExportDataModalContent() {
    const { evaluationContext, propertyConfigs } = useDashboardContext();
    const evaluator = new Evaluator(new PropertySource(evaluationContext.properties, propertyConfigs));
    const { state } = useWidgetContext();
    const [completeExport, setCompleteExport] = useState(false);
    const sources = listWidgetSources(state.widget);
    const sourceIds = sources.map(source => source.source);
    const source = sources[0];
    const widgetParams = mapSourceParamsToObject(source.params);

    return (
        <Formik<FormValues>
            initialValues={{
                source: source.source,
                params: widgetParams,
                fileName: 'export',
                resultType: EXPORT_FORMATS[1].resultType
            }}
            enableReinitialize
            render={(params: FormikProps<FormValues>) => (
                <div>
                    {
                        state.widget.type == "REPORT" ?
                            <ExportDataModalForm
                                {...params}
                                widgetParams={widgetParams}
                                sourceIds={sourceIds}
                            />
                        :
                            <DataSourceExportDataModalForm
                                {...params}
                                widgetParams={widgetParams}
                                sourceIds={sourceIds}
                                completeExport={completeExport}
                                setCompleteExport={setCompleteExport}
                            />
                    }
                    
                </div>
            )}
            onSubmit={(values: FormValues, helpers) => {
                downloadReport(values, state.widget, evaluator, completeExport).finally(() =>
                    helpers.setSubmitting(false)
                );
            }}
        />
    );
}

function mapSourceParamsToObject(params: ReportParamConfig[]): { [key: string]: string } {
    const objects: { [key: string]: string } = {};
    if(params !== undefined) {
        for (let param of params) {
            objects[param.name] = param.value;
        }
    }
    return objects;
}
