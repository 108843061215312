import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PagerContainer from '../../common/PagerContainer';
import { SortColumn } from './SingleSourceWidgetGrid';
import { RESULTS_PER_PAGE } from './singleSourceRequestHandler';
import ReactTableGrid from 'core/dashboard/components/layout/ReactTableGrid';
import { getTableColumns, ReactTableColumnType } from 'core/dashboard/utils/react-table/ReactTableUtils';
import { DashboardContext } from 'core/dashboard/components/dashboard/context';
import { Column } from '@pearlchain/component-lib-react-pg';
import { ActionConfig, ActionHandler } from 'core/dashboard/types/actionHandlerTypes';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';

type Props = {
    data: {[key: string]: unknown;}[] | undefined;
    totalResults: number;
    fetcher: (pageOffset: number) => Promise<any>;
    columns: Column[];
    handleSortColumns: (cols: SortColumn[]) => void
    nextPage?: boolean;
    widgetError?: any;
    actionHandler: ActionHandler
    actions: WidgetActions
    isDefault: boolean
}

export type NewChangeType = {
    index: number,
    column: string,
    newValue: string
}

export type RowActionType = {
    actionConfig: ActionConfig,
    index: number
}

export default function SingleSourcePager(props: Props) {
    const { data, totalResults, fetcher, handleSortColumns, columns, nextPage, actions } = props;
    const { t } = useTranslation();
    const ctx = useContext(DashboardContext).evaluationContext;

    const [stateData, setStateData] = useState(data)
    const [newChange, setNewChange] = useState({} as NewChangeType)
    const [rowAction, setRowAction] = useState({} as RowActionType)

    useEffect(() => {
        if(JSON.stringify(newChange) !== '{}'){
            if(stateData){
                const newArr = stateData.map(a => ({...a}))
                let aux = {...newArr[newChange.index]}
                aux[newChange.column] = newChange.newValue;
                newArr[newChange.index] = aux
                setStateData(newArr)
            }
        }
    }, [newChange])

    useEffect(() => {
        if(JSON.stringify(rowAction) !== '{}'){
            if(stateData){
                const row = stateData[rowAction.index]
                props.actionHandler(rowAction.actionConfig, {row: row})
            }
        }
    }, [rowAction])

    let columnsReactTable = getTableColumns(t, columns, ctx, data ? data : [], actions, setNewChange, setRowAction)
    
    return (
        <PagerContainer
            data={data}
            totalResults={totalResults}
            resultsPerPage={RESULTS_PER_PAGE}
            fetcher={fetcher}
            nextPage={nextPage}
            isDefault={props.isDefault}
            render={useCallback((data: { [key: string]: unknown }[] = []) => {
                setStateData(getData(data,props.isDefault))
                return (
                    <ReactTableGrid
                        data={getData(data,props.isDefault)}
                        columns={columnsReactTable}
                        onSortColumnsChanged={(cols) => {
                            // update reference to the grid sort columns
                            handleSortColumns(cols);
                        }}
                    />
                );
            }, [columns])}
            widgetError={props.widgetError}
        />
    )
}

//function to get only the first 50 results, for pagination purposes
const getData = (data: { [key: string]: unknown }[], isDefault:boolean) => {
    if(data && data.length > RESULTS_PER_PAGE){
        if(!isDefault){
            return data
        }
        return data.slice(0,-1);
    }
    else {
        return data;
    }
}
