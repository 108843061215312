import React, { useCallback, useState, useEffect } from 'react';
import { Form, FormGroup, Col, Button } from 'react-bootstrap';
import { useFetcher } from '@pearlchain/component-lib-common';
import { Formik, FastField } from 'formik';

import { fetchTaskDetailInfo, submitTask, saveTask, approveTask } from '../../humanTasksRequests';
import { HumanTaskFormPropertyResponse, HumanTaskOverviewResponse } from '../../types/responseTypes';
import FormGeneratorFieldInput from './FormGeneratorFieldInput';
import FormFieldErrorFeedback from '../../../common/config/formik/FormFieldErrorFeedback';
import { ServiceMessageResultResponse } from '../../../../../types/responseTypes';
import { useRequestHandler } from '../../../../../utils/hooks/useRequestHandler';
import { useTranslation } from 'react-i18next';

function getFormInitialValues(fields: HumanTaskFormPropertyResponse[]) {
    const initialValues: { [key: string]: unknown } = {};
    for (let field of fields) {
        if (field.value != null) {
            initialValues[field.id] = field.value;
        } else if (field.type === 'boolean') {
            initialValues[field.id] = false;
        } else {
            initialValues[field.id] = '';
        }
    }
    return initialValues;
}

type FormProps = {
    task: HumanTaskOverviewResponse;
    fields: HumanTaskFormPropertyResponse[];
    onTaskSubmitted: () => void;
    onShowServiceMessage: (serviceMessage: ServiceMessageResultResponse) => void;
};

function createValidator(field: HumanTaskFormPropertyResponse) {
    const fieldType = field.type;
    const required = field.required;
    return (value: any) => {
        if (required) {
            if (fieldType === 'boolean') {
                if (value !== true && value !== false) return 'Must be true or false';
            } else if (value == null || value === '') return 'Required field';
        }
    };
}

function FormGenerator(props: FormProps) {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);
    const [isApproved, setApproved] = useState<boolean>();
    //Used to disable the approve button if the reject button has been clicked.
    //Separate from isApproved, because the useState gives undefined as default value, and !undefined gives true, the same as !false. Therefore, the approved
    //button would start as disabled, which is not behaviour we want.
    const [isRejected, setRejected] = useState<false>();

    const initialValues = getFormInitialValues(props.fields);
    const taskId = props.task.taskId;
    //Defines that a task is marked as ApprovalHumanTask. Makes it so the Submit button is not shown.
    const isApproval = props.task.approvalHT;
    //Defines that a task is marked as SaveableHumanTask. Makes it so the Save button is shown.
    const isSaveable = props.task.saveableHT;
    //Defines that a task is marked as SaveableHumanTask. Makes it so the Save button is shown.
    const isRejectable = props.task.rejectableHT;

    const submitBtnLabel = props.task.submitBtnLabel;
    const approveBtnLabel = props.task.approveBtnLabel;
    const rejectBtnLabel = props.task.rejectBtnLabel;
    const saveBtnLabel = props.task.saveBtnLabel;

    const rejectReasonId="WI_RejectableHumanTask_RejectReason";
    const rejectReason: HumanTaskFormPropertyResponse = {
        id: rejectReasonId,
        name: "Reject Reason",
        type: "textArea",
        value: "",
        enumerationValues: {},
        required: true,
        readable: true,
        writable: true
    }

    const handler = useRequestHandler(submitTask, 'Submit Task');
    function handleSubmit(values: { [key: string]: unknown }) {
        let toSave: { [key: string]: unknown } = {}
        for (let field of props.fields.filter((f) => f.writable)) {
            toSave[field.id] = values[field.id]
        }
        if(values[rejectReasonId] != undefined) {
            toSave[rejectReasonId] = values[rejectReasonId];
        }
        setSubmitting(true);
        handler(taskId, toSave, isApproved)
            .then(msg => {
                if (!msg.messages.length) {
                    props.onTaskSubmitted();
                    return;
                } else {
                    props.onShowServiceMessage(msg);
                    setSubmitting(false);
                }
            });
    }

    const saveHandler = useRequestHandler(saveTask, 'Save Task');
    //Abusing reset function for saving as it's not easy getting the form values otherwise
    function handleReset(values: { [key: string]: unknown }) {
        let toSave: { [key: string]: unknown } = {}
        for (let field of props.fields.filter((f) => f.writable)) {
            toSave[field.id] = values[field.id]
        }
        saveHandler(taskId, toSave)
            .then(msg => {
                if (!msg.messages.length) {
                    props.onTaskSubmitted();
                    return;
                } else {
                    props.onShowServiceMessage(msg);
                }
            });
    }

    function approve() {
        setApproved(true);
        setRejected(false);
    }

    function reject() {
        setApproved(false);
        setRejected(true);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onReset={handleReset}
            render={({ handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <div className="form-scroll-region">
                        {props.fields.map(field => {
                            const id = 'ht-detail-' + field.name;

                            return (
                                <FormGroup className={"ht-detail-inputs-"+isApproved} key={field.name}>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Label>{field.name}</Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <FastField
                                                name={field.id}
                                                id={id}
                                                formField={field}
                                                validate={createValidator(field)}
                                                component={FormGeneratorFieldInput}
                                            />
                                            <FormFieldErrorFeedback name={field.id} />
                                        </Col>
                                    </Form.Row>
                                </FormGroup>
                            )
                        })}
                        {isApproved != undefined && !isApproved && (
                            <FormGroup key={rejectReason.name}>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Label>{rejectReason.name}</Form.Label>
                                    </Col>
                                    <Col md={6}>
                                        <FastField
                                            name={rejectReason.id}
                                            id={'ht-detail-'+rejectReason.name}
                                            formField={rejectReason}
                                            validate={createValidator(rejectReason)}
                                            component={FormGeneratorFieldInput}
                                        />
                                        <FormFieldErrorFeedback name={rejectReason.id} />
                                    </Col>
                                </Form.Row>
                            </FormGroup>
                        )}
                    </div>
                    <Button type="submit" className="ht-detail-btn ht-detail-submit" hidden={isApproval} disabled={isSubmitting}>
                        { submitBtnLabel != null ? t(submitBtnLabel) : t('humantask.detail.submit') }
                    </Button>
                    <Button type="reset" className="ht-detail-btn" hidden={(!isSaveable || !isApproval)} disabled={isSubmitting}>
                        { saveBtnLabel != null ? t(saveBtnLabel) : t('humantask.detail.save')  }
                    </Button>
                    <Button type="submit" className="ht-detail-btn ht-detail-approve" hidden={!isApproval} disabled={isSubmitting || isRejected} onClick={approve}>
                        { approveBtnLabel != null ? t(approveBtnLabel) : t('humantask.detail.approve') }
                    </Button>
                    <Button type="submit" className="ht-detail-btn ht-detail-reject" hidden={!isRejectable} disabled={isSubmitting} onClick={reject}>
                        { rejectBtnLabel != null ? t(rejectBtnLabel) : t('humantask.detail.reject') }
                    </Button>
                </Form>
            )}
        />
    );
}

type Props = {
    task: HumanTaskOverviewResponse;
    onTaskSubmitted: () => void;
    onShowServiceMessage: (serviceMessage: ServiceMessageResultResponse) => void;
};

export default function HumanTaskDetailForm({
    task,
    onShowServiceMessage,
    onTaskSubmitted
}: Props) {
    const taskId = task.taskId
    const { data } = useFetcher(useCallback(() => fetchTaskDetailInfo(taskId), [taskId]));
    
    useEffect(() => {
        const field = data && data[0];

        if (field) {
            const fieldId = 'ht-detail-' + field.name;
            const el = document.getElementById(fieldId);

            if (el) {
                el.focus();
            }
        }
    }, [data])

    return data ? (
        <FormGenerator
            onTaskSubmitted={onTaskSubmitted}
            onShowServiceMessage={onShowServiceMessage}
            task={task}
            fields={data}
        />
    ) : null;
}
